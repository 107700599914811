const updateAirportList = (state, action) => {
  if (state === undefined) {
    return {
      airports: [],
      error: null
    }
  }
  switch (action.type) {
    case 'FETCH_AIRPORTS_REQUEST':
      return {
        airports: [],
        error: null,
      };
    case 'FETCH_AIRPORTS_SUCCESS':
      return {
        airports: action.payload,
        error: null
      };
    case 'FETCH_AIRPORTS_FAILURE':
      return {
        airports: [],
        error: action.payload
      };
    default:
      return state.airportsList;
  }
};

export default updateAirportList;