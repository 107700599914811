const updateFlightList = (state, action) => {
  if (state === undefined) {
    return {
      flights: [],
      loading: false,
      error: null
    }
  }
  switch (action.type) {
    case 'FETCH_FLIGHTS_REQUEST':
      return {
        flights: state.flightList.flights,
        loading: true,
        error: null,
      };
    case 'FETCH_FLIGHTS_SUCCESS':
      return {
        flights: state.flightList.flights.concat(action.payload),
        loading: false,
        error: null
      };
    case 'FETCH_FLIGHTS_FAILURE':
      return {
        flights: [],
        loading: false,
        error: action.payload
      };
    case 'FETCH_FLIGHTS_RESTORE':
      return {
        flights: [],
        loading: false,
        error: null
      };
    default:
      return state.flightList;
  }
};

export default updateFlightList;