import React from "react";

const PageInfo = () => {

  return (
    <div className="jumbotron container py-6">
      <h5>The Check-Tickets.Info is a project represented by Sirena-Travel CJSC, the largest
        Russian company providing comprehensive services for booking and selling air tickets.</h5>
      <p>Check-Tickets.Info is a step towards our clients - air passengers.</p>
      <br/><p>We are pleased to offer you information on the following sections:</p>
      <p>"CHECK BOOK": You have booked your air transportation through the Sirena-Travel system,
        on an Internet resource or in an agency? - You can get information about the status of your order:
        route, terms of service. You have booked and paid for transportation with an e-ticket, but
        did not get a route on the receipt? - You can get it in this section.</p>
      <p>
        "CHECK-FLIGHT": Are you planning your trip and want to receive up-to-date information on the flight schedule?
        - This section contains the most reliable information about the flight schedule of all airlines,
        whose information is presented in the Sirena-Travel system.
      </p>
      <p>Dozens of airlines and hundreds of agencies work with the Sirena-Travel system. You may have already become
        our client when you made your flight. Now we have become closer to you.</p>
      <p>Enjoy your flight!</p>
    </div>
  )
};

export default PageInfo;