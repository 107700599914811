import React from "react";
import "./spinner.css";

const Spinner = () => {
  return (
      <div className="loadingio-spinner-eclipse-di1l7yn662n">
        <div className="ldio-3ezp3s950km">
          <div></div>
        </div>
      </div>
  )
};

export default Spinner;