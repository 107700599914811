import { createStore, applyMiddleware } from "redux";
import reducer from "./reducers";
import thunkMiddleware from "redux-thunk";

const logMiddleware = () => (dispatch) => (action) => {
  return dispatch(action);
};

const store = createStore(reducer, applyMiddleware(thunkMiddleware, logMiddleware));

const myActionCreator = (timeout) => (dispatch) => {
  setTimeout(() => dispatch({
    type: 'delayed_action'
  }), timeout)
};

store.dispatch(myActionCreator(3000));

export default store;