import React from "react";
import {AutocompleteServiceConsumer} from "../flight-service-context";

const withAirportService = () => (Wrapped) => {
  return (props) => {
    return (
      <AutocompleteServiceConsumer>
        {
          (airportService) => {
            return <Wrapped {...props} airportService={airportService}/>
          }
        }
      </AutocompleteServiceConsumer>
    )
  }
};

export default withAirportService;