const flightsLoaded = (newFlights) => {
  return {
    type: 'FETCH_FLIGHTS_SUCCESS',
    payload: newFlights
  }
};

const flightsRequested = () => {
  return {
    type: 'FETCH_FLIGHTS_REQUEST'
  }
};

const flightsError = (error) => {
  return {
    type: 'FETCH_FLIGHTS_FAILURE',
    payload: error
  }
};

const airportsRequested = () => {
  return {
    type: 'FETCH_AIRPORTS_REQUEST'
  }
};

const airportsLoaded = (airports) => {
  return {
    type: 'FETCH_AIRPORTS_SUCCESS',
    payload: airports
  }
};

const airportsError = (error) => {
  return {
    type: 'FETCH_AIRPORTS_FAILURE',
    payload: error
  }
};

const resetFlights = () => {
  return {
    type: 'FETCH_FLIGHTS_RESTORE'
  }
};

const fetchFlights = (flightService, dispatch) => (fnum, date) => {
  dispatch(flightsRequested());
  flightService.getFlightStatus(fnum, date)
    .then((data) => dispatch(flightsLoaded(data)))
    .catch((error) => dispatch(flightsError(error)));
};

const restoreFlights = (dispatch) => () => {
  dispatch(resetFlights());
};

const fetchTable = (flightService, dispatch) => (name, date) => {
  dispatch(flightsRequested());
  flightService.getTable(name, date)
    .then((data) => dispatch(flightsLoaded(data)))
    .catch((error) => dispatch(flightsError(error)));
};

const fetchAirports = (airportService, dispatch) => (val) => {
  dispatch(airportsRequested());
  airportService.getSuggests(val)
    .then((data) => dispatch(airportsLoaded(data)))
    .catch((error) => dispatch(airportsError(error)));
};

export {
  fetchFlights,
  fetchAirports,
  fetchTable,
  restoreFlights
};