import React from "react";
import {compose} from "../../utils";
import {withFlightService} from "../hoc";
import {connect} from "react-redux";
import {fetchFlights, restoreFlights} from "../../actions";
import {ErrorBoundry} from "../error";
import {FlightItemSmall} from "../flight-item";

let names = '';

const OnSubmit = (e, fetchFlights, inf, restoreFlights) => {
  e.preventDefault();
  restoreFlights();
  if (document.getElementById("number").value !== '' &&
    document.getElementById("name").value !== '') {
    const number = document.getElementById("number").value;
    const name = document.getElementById("name").value;
    if (checkInfo(inf, name, number)) {
      for (let i = 0; i < inf.length; i++) {
        if (inf[i].secure === number) {
          fetchFlights(inf[i].number, inf[i].date);
          names = inf[i].name.fname + " " + inf[i].name.lname
        }
      }
    }
  } else {
    alert("Error in pointing information!")
  }
};

const checkInfo = (info, name, number) => {
  let res = false;
  for (let i = 0; i < info.length; i++) {
    if (info[i].name.lname.toLowerCase() === name.toLowerCase() && info[i].secure === number) {
      res = true;
    }
  }
  if (!res) alert("No information found");
  return res;
};

const getResource = async () => {
  const res = await fetch("/info.json?" + Date.now());
  return await res.json();
};

const getData = async () => {
  return await getResource();
};
let information = '';
getData().then(item => information = item.data);

const PageCheck = ({ fetchFlights, flights, restoreFlights }) => {

  let info = <h3>Please fill fields</h3>;

  if (flights.length) {
    const items = (arr) => {
      return arr.map((item, idx) => {
        return <FlightItemSmall flights={item} info={information}
                                snumber={document.getElementById("number").value}
                                key={idx}/>
      })
    };
    info = (<div>
      <p>This is your information :</p>
      <p>Name: {names}</p>
      <table className="table">
        <thead>
          <tr>
            <th>Flight</th>
            <th>Departure</th>
            <th>Arrival</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {items(flights)}
        </tbody>
      </table>
    </div>);
  }

  return (
    <div className="py-6">
      <div className="container jumbotron">
        <h1 className="h1">Check your booked flight</h1>
        <p>Here you can check your flight information with your secure number.</p>
        <div className="row">
          <div className="col-sm-3">
            {/*}<p><a className="btn btn-primary btn-lg" href="#" role="button">Learn more &raquo;</a></p>{*/}
            <form className="form">
              <div className="form-group row">
                <div className="col-12">
                  <label htmlFor="number" className="col-form-label">Your secure number</label>
                  <input id="number" className="form-control mr-sm-2" type="text" placeholder="Secure number" aria-label="Secure number" required/>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <label htmlFor="name" className="col-form-label">Your last name</label>
                  <input id="name" className="form-control mr-sm-2" type="text" placeholder="Last name" aria-label="Last name" required/>
                </div>
              </div>
              <button className="btn btn-success my-2 my-sm-1" type="submit"
                      onClick={(e) =>
                        OnSubmit(e, fetchFlights, information, restoreFlights)}>Search</button>
            </form>
          </div>
          <div className="col-sm-9">
            <ErrorBoundry>
              {info}
            </ErrorBoundry>
          </div>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = ({ flightList: {flights} }) => {
  return {
    flights: flights
  }
};

const mapDispatchToProps = (dispatch, { flightService }) => {
  return {
    fetchFlights: fetchFlights(flightService, dispatch),
    restoreFlights: restoreFlights(dispatch)
  }
};

export default compose(
  withFlightService(),
  connect(mapStateToProps, mapDispatchToProps)
)(PageCheck);
