import React from "react";
import {FlightItem} from "../flight-item";

const FlightList = ({dest, flights}) => {
  const items = (arr, ud) => {
    return arr.map((item) => {
      return <FlightItem flights={item} key={item.number} mov={ud}/>
    })
  };

  return (
    <div id="flight_list">
      <h2>Timetable for {dest}</h2>
      <table className="table" id="table">
        <thead>
          <tr>
            <th onClick={() => sortTable(0)} >Flight</th>
            <th onClick={() => sortTable(1)} >Movement</th>
            <th onClick={() => sortTable(2)} >Status</th>
          </tr>
        </thead>
        <tbody>
          {items(flights[0].arrivals, "down")}
          {items(flights[0].departures, "up")}
        </tbody>
      </table>
    </div>
  )
};

function sortTable(n) {
  let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("table");
  switching = true;
  // Set the sorting direction to ascending:
  dir = "asc";
  /* Make a loop that will continue until
  no switching has been done: */
  while (switching) {
    // Start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /* Loop through all table rows (except the
    first, which contains table headers): */
    for (i = 1; i < (rows.length - 1); i++) {
      // Start by saying there should be no switching:
      shouldSwitch = false;
      /* Get the two elements you want to compare,
      one from current row and one from the next: */
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /* Check if the two rows should switch place,
      based on the direction, asc or desc: */
      if (dir === "asc") {
        if (x.children[0].innerHTML.toLowerCase() > y.children[0].innerHTML.toLowerCase()) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      } else if (dir === "desc") {
        if (x.children[0].innerHTML.toLowerCase() < y.children[0].innerHTML.toLowerCase()) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
      and mark that a switch has been done: */
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      // Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /* If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again. */
      if (switchcount === 0 && dir === "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}

export default FlightList;