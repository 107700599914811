export default class AutocompleteService {

  _apiBase = 'https://aerodatabox.p.rapidapi.com/airports/search/term?q=';

  async getResource(val) {
    const res = await fetch(`${this._apiBase}${val}`, {
        method: "GET",
        headers: new Headers({
          "x-rapidapi-host": "aerodatabox.p.rapidapi.com",
          "x-rapidapi-key": "0f03a246a4msh6c4b442cee27f06p1eac2ajsn5dc86cc30cc4"
        })
      });
    if (!res.ok) {
      throw new Error(`Could not fetch autocomplete url, received ${res.status}`)
    }
    return await res.json();
  }

  getSuggests = async (val) => {
    const res = await this.getResource(val);
    return res.items.map(this._transformSuggests);
  };

  _transformSuggests = (sug) => {
    return {
      name: sug.name,
      code: sug.iata,
      icao: sug.icao,
      air_name: sug.shortName,
      country_name: sug.countryCode,
    }
  };

}