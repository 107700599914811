export default class FlightService {

  _apiBase = 'https://aerodatabox.p.rapidapi.com/flights/';
  _apiHost = 'aerodatabox.p.rapidapi.com';
  _apiKey = ["4e449d5228mshe60b14e81b29765p171207jsn0986e581b3cf",
    "753ade43e7mshee817164d9acf4fp194bb7jsnc89c6536477a",
    "d1cbc172b2msh50c8f49a27fab84p1f3ec2jsn5995af3ef245",
    "938f188b7bmsh53609c145252819p15d29fjsn836a599b6e74",
    "3d4bbb8ce4msh96992e30820e07cp1d6bd2jsnbb1620529c51",
    "c317af5df2mshfbb89fe69f1f54ep15e260jsn5d94e763360a",
    "0959290bcdmshf5f2b983d2d0b3cp1806bfjsnb7ee8e80228e",
    "97450fc30fmsh64d7fb4a2c4d9aep131d82jsn0cfeeea3872c",
    "d602b7aae6msh2f9c956f367e188p1a3934jsn61851edc085a",
    "f99dc103dbmsh6b6f325f5465753p1b2072jsn9f7eb745e4c7",
    "469664f4b0mshfcf6b5a65bd0fc9p12e5ebjsn20b64dfc3897",
    "0f03a246a4msh6c4b442cee27f06p1eac2ajsn5dc86cc30cc4",
    "eec3c83aebmsh76bab268ba18868p1c3772jsn9317ef09ec6a",
    "zUTo2Vof1JmshVaJb5lFTGLKrKqUp1gstQmjsnGvROHAfOHTPc",
    "6d1a1ee2cdmsh68f602cb5934c8ep168c99jsna751a55dfa07",
    "e6cc1e7559msh099f100aeaba1f8p129df1jsnff3baa24cdd2",
    "d16af0170emsh4e232479460ad25p1b5cb6jsn0a57e8402895",
    "4797275820mshe953122d379a3bap1194d6jsnedacd02bc142",
    "c964d90ec7msh26f576cb56935fap1aa3cajsn4ccf3e209189",
    "9310f1f784msh498d2dc40b17f2cp112393jsn0a3abfc7686d",
    "48211f6defmsh1c1ecdadfa6184cp157411jsnfc68c5223ea1",
    "2996c8146bmsh0b2396937d91adbp1bd629jsnccf396ec4ed3",
    "68b64cc0b7mshe6141827dd47310p1e5f09jsn496e17b4f569",
    "b2d8d7aa7amsh4465108ddabebebp1b8dedjsndc5cd11ad006",
    "559736de3emsh1101a9bfb69c6d2p103aedjsn41834b6910a2",
    "66c99bd639mshaffb02c96bef33cp176801jsne38b872bcc94",
    "732f0e0e16mshc615b06b8dff47bp168bbajsn22307bbe4078"];

  async getResource(url, i) {
    const res = await fetch(`${this._apiBase}${url}`, {
      'method': "GET",
      'headers': new Headers({
          "x-rapidapi-host": `${this._apiHost}`,
          "x-rapidapi-key": `${this._apiKey[i]}`
        })
    });
    if (!res.ok) {
      return await this.getResource(url, i + 1);
    }
    return await res.json();
  }

  getFlightStatus = async (fnum, date) => {
    const res = await this.getResource(`number/${fnum}/${date}`, 0);
    return res.map(this._transformFlight);
  };

  getTable = async (name, date = '') => {
    date = new Date();
    date.setHours(date.getHours() + 2); const t1 = date.toJSON();
    date.setHours(date.getHours() + 6); const t2 = date.toJSON();
    let res = await this.getResource(
      `airports/icao/${name}/${t1.slice(0, 16)}/${t2.slice(0, 16)}`, 0);
    const her = {
      arrivals: {},
      departures: {}
    };
    her.arrivals = res.arrivals.slice(res.arrivals.length > 50 ? 50 : res.arrivals.length).map(this._transformSchedule);
    her.departures = res.departures.slice(res.arrivals.length > 50 ? 50 : res.arrivals.length).map(this._transformSchedule);

    return her;
  };

  _transformFlight = (flight) => {
    return {
      aircraft: flight.aircraft.model,
      airline: flight.airline.name,
      arrival: {
        airport: {
          iata: flight.arrival.airport.iata,
          icao: flight.arrival.airport.icao,
          name: flight.arrival.airport.name,
          countryCode: flight.arrival.airport.countryCode
        },
        scheduledTimeLocal: flight.arrival.scheduledTime.local,
      },
      departure: {
        airport: {
          iata: flight.departure.airport.iata,
          icao: flight.departure.airport.icao,
          name: flight.departure.airport.name,
          countryCode: flight.departure.airport.countryCode
        },
        scheduledTimeLocal: flight.departure.scheduledTime.local
      },
      number: flight.number,
      status: flight.status
    }
  };

  _transformSchedule = (flight) => {

    return {
      aircraft: flight.aircraft.model,
      airline: flight.airline.name,
      movement: {
        airport: {
          iata: flight.movement.airport.iata,
          icao: flight.movement.airport.icao,
          name: flight.movement.airport.name,
        },
        scheduledTimeLocal: flight.movement.scheduledTime.local
      },
      number: flight.number,
      status: flight.status,
      terminal: flight.movement.gate
    }
  };

}