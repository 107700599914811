import React from "react";
import { Link } from "react-router-dom";

const PageHome =() => {

  return (
    <div className="py-6 text-center">
      <div className="container jumbotron py-6" style={{background:"transparent"}}>
        <p className="h1">Here you can check all the information of your flight</p>
        <div className="row p-5">
          <p className="col"><Link to="check" className="btn btn-primary btn-lg" role="button">Check book</Link></p>
          <p className="col"><Link to="search" className="btn btn-primary btn-lg" role="button">Check flights</Link></p>
        </div>
      </div>
    </div>
  )
};

export default PageHome;