import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {PageHome, PageInfo, PageSearch, PageCheck} from "../pages";
import Footer from "../footer";
import Header from "../header";
import FlightService from "../../services/flight-service";
import AutocompleteService from "../../services/autocomplete-service";
import { AutocompleteServiceProvider } from "../flight-service-context";
import { FlightServiceProvider } from "../flight-service-context";
import {ErrorBoundry} from "../error";

const Autocomplete = new AutocompleteService();
const Flights = new FlightService();

const App = () => {
  return (
    <main role="main">
      <Router>
        <Switch>
          <Route path='/' exact render={({match}) => {
            return (
              <div>
                <Header route={match.path}/>
                <PageHome/>
              </div>
            )
          }}/>
          <Route path='/search' exact render={({match}) => {
            return (
              <AutocompleteServiceProvider value={Autocomplete}>
                <FlightServiceProvider value={Flights}>
                  <Header route={match.path}/>
                  <ErrorBoundry>
                    <PageSearch/>
                    <Footer/>
                  </ErrorBoundry>
                </FlightServiceProvider>
              </AutocompleteServiceProvider>
            )
          }}/>
          <Route path='/check' exact render={({match}) => {
            return (
              <FlightServiceProvider value={Flights}>
                <Header route={match.path}/>
                <ErrorBoundry>
                  <PageCheck/>
                  <Footer/>
                </ErrorBoundry>
              </FlightServiceProvider>
            )
          }}/>
          <Route path='/about' exact render={({match}) => {
            return (
              <div>
                <Header route={match.path}/>
                <PageInfo/>
                <Footer/>
              </div>
            )
          }}/>
          <Route render={() => (
            <div>
              <Header/>
              <div className="container jumbotron bg-transparent">
                <h2>Page not found: 404</h2>
              </div>
            </div>)}/>
        </Switch>
      </Router>
    </main>
  );
};

export default App;
