import updateFlightList from "./flight-list";
import updateAirportList from "./auto-complete";

const reducer = (state, action) => {
  return {
    flightList: updateFlightList(state, action),
    airportsList: updateAirportList(state, action)
  };
};

export default reducer;