import React from "react";
import './error.css';

const ErrorIndicator = ({ msg }) => {
  console.log(msg);
  return (
    <div>
      <h2>Error! Something happened</h2>
      <p>Some error has happened. We already took care of this.</p>
    </div>
  )
};

export default ErrorIndicator;