import React from "react";

const FlightItem = ({ flights, mov }) => {
  let codes = "";
  if (flights.movement.airport.iata) {
    codes = ` (${flights.movement.airport.iata}/${flights.movement.airport.icao})`;
  }

  if (mov === "up") { mov = "🛫 arriving at " } else { mov = "🛬 departing from "}

  return (
    <tr>
      <td><div>{flights.number}</div>
        {flights.airline}<br/>
        {flights.aircraft}
      </td>
      <td><div>{flights.movement.scheduledTimeLocal ? flights.movement.scheduledTimeLocal.slice(0, 16) : ''}</div>
        {mov}<br/>{flights.movement.airport.name}{codes}{flights.movement.airport.countryCode ?
          `, ${flights.movement.airport.countryCode}` : ""}
      </td>
      <td>
        <div>{flights.status}</div>
        {flights.terminal}
      </td>
    </tr>
  )
};

const FlightItemSmall = ({ flights, info, snumber }) => {
  const content = (flights, status = flights.status) => {
    let codes1, codes2 = '';
    if (flights.departure.airport.iata) {
      codes1 = ` (${flights.departure.airport.iata}/${flights.departure.airport.icao})`;
    }
    if (flights.arrival.airport.iata) {
      codes2 = ` (${flights.arrival.airport.iata}/${flights.arrival.airport.icao})`;
    }
    return (
      <tr>
        <td>{flights.number}<br/>
          {flights.airline}<br/>
          {flights.aircraft}
        </td>
        <td>
          {flights.departure.airport.name}&nbsp;
          {codes1}{flights.departure.airport.countryCode ? `, ${flights.departure.airport.countryCode}` : ''}<br/>
          {flights.departure.scheduledTimeLocal ? flights.departure.scheduledTimeLocal.slice(0, 16) : ''}
        </td>
        <td>{flights.arrival.airport.name}&nbsp;
          {codes2}{flights.arrival.airport.countryCode ? `, ${flights.arrival.airport.countryCode}` : ''}<br/>
          {flights.arrival.scheduledTimeLocal ? flights.arrival.scheduledTimeLocal.slice(0, 16) : ''}
        </td>
        <td>{status}</td>
      </tr>
    )
  };

  if (info) {
    for (let i = 0; i < info.length; i++) {
      if (info[i].secure === snumber && info[i].number === flights.number) {
        if (info[i].time === flights.departure.scheduledTimeLocal.slice(11, 16)) {
          return content(flights, info[i].status)
        } else {
          console.log(flights, info[i])
          return null
        }
      }
    }
  } else {
    return content(flights)
  }
};

export {
  FlightItem,
  FlightItemSmall
};
