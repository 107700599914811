import React from "react";
import {Link} from "react-router-dom";
import {compose} from "../../utils";
import {connect} from "react-redux";
import img from "../../img/android-chrome-512x512.png";

const Header = ({ route, dispatch}) => {

  const activeBtn = "nav-item active mx-auto";
  const secndBtn = "nav-item mx-auto";

  return (
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <div className="col-9">
          <Link className="navbar-brand" to="/" onClick={() => dispatch({type: 'FETCH_FLIGHTS_RESTORE'})}>
            <img src={img} height="20" alt={'logo'}/>&nbsp;
            Check flights</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
                  aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation"
                  onClick={() => dispatch({type: 'FETCH_FLIGHTS_RESTORE'})}>
            <span className="navbar-toggler-icon"/>
          </button>
        </div>

        <div className="col collapse navbar-collapse" id="navbarsExampleDefault">
          <ul className="navbar-nav mr-auto">
            <li className={route === "/" ? activeBtn : secndBtn}>
              <Link className="nav-link" to="/" onClick={() => dispatch({type: 'FETCH_FLIGHTS_RESTORE'})}>Home <span className="sr-only">(current)</span></Link>
            </li>
            <li className={route === "/check" ? activeBtn : secndBtn}>
              <Link className="nav-link" to="check" onClick={() => dispatch({type: 'FETCH_FLIGHTS_RESTORE'})}>Check</Link>
            </li>
            <li className={route === "/search" ? activeBtn : secndBtn}>
              <Link className="nav-link" to="search" onClick={() => dispatch({type: 'FETCH_FLIGHTS_RESTORE'})}>Search</Link>
            </li>
            <li className={route === "/about" ? activeBtn : secndBtn}>
              <Link className="nav-link" to="about" onClick={() => dispatch({type: 'FETCH_FLIGHTS_RESTORE'})}>About</Link>
            </li>
          </ul>
        </div>
      </nav>
  )
};

export default compose(
  connect()
)(Header);