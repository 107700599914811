import React from "react";
import {FlightServiceConsumer} from "../flight-service-context";

const withFlightService = () => (Wrapped) => {
  return (props) => {
    return (
      <FlightServiceConsumer>
        {
          (flightService) => {
            return <Wrapped {...props} flightService={flightService}/>
          }
        }
      </FlightServiceConsumer>
    )
  }
};

export default withFlightService;