import React from "react";

const Footer = () => {

  const date = new Date();

  return (
    <footer className="footer mt-auto">
      <div className="container">
          <span className="text-muted">
            <p>&copy; Company group Check Flights "Don't be late" 2008-{date.getFullYear()}
              <br/>Email: <a href="mailto:support@check-tickets.info">support@check-tickets.info</a></p>
          </span>
      </div>
    </footer>
  )
};

export default Footer;