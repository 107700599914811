import React from "react";
import FlightList from "../flight-list";
import { connect } from "react-redux";
import {fetchAirports, fetchTable, fetchFlights, restoreFlights} from "../../actions";
import {compose} from "../../utils";
import {withAirportService, withFlightService} from "../hoc";
import {ErrorBoundry} from "../error";
import Spinner from "../spinner";
import {FlightItemSmall} from "../flight-item";

const autoComplete = (items, dest) => {
  if (items.length === 0) {
    return (<button className="dropdown-item list-group-item disabled" key='0'>Start typing...</button>);
  } else {
    let arr = [];
    for (let i = 0; i < items.length && i < 5; i++) {
      arr.push(items[i]);
    }
    return arr.map(({name, code, icao, country_name}, idx) => {
      let str = `${name} (${code}/${icao}), ${country_name}`;
      return (<button className="dropdown-item list-group-item w-100" key={idx} id={`${icao} ${dest}`}
                      onClick={(e) => putCode(e)}>
        {str.replace("  ", " ")}
      </button>)
    })
  }
};

const putCode = (e) => {
  e.preventDefault();
  const el = document.getElementById(e.target.id.slice(5));
  el.value = e.target.innerHTML;
};

const updateSuggests = (updateAirport, phrase = "") => {
  if (phrase !== "" && phrase.length > 2) {
    updateAirport(phrase);
  }
};

let dest = "";

const OnSubmit = (e, updateFlightList, restoreFlights) => {
  restoreFlights();
  e.preventDefault();
  const reg = /([A-Z]{4})\)/;
  if (document.getElementById("departure").value !== '' &&
    //document.getElementById("date").value !== '' &&
    document.getElementById("departure").value.match(reg) !== null) {
      const departure = document.getElementById("departure").value.match(reg)[1];
      //const date = document.getElementById("date").valueAsDate;
      //alert(`this is information: ${date} ${departure}`);
      dest = document.getElementById("departure").value;
      updateFlightList(departure);//, date);
  } else {
    alert("Error in pointing information!")
  }
};

const searchFlight = (e, updateFlightList, restoreFlights) => {
  restoreFlights();
  e.preventDefault();
  if (document.getElementById("number").value !== '' &&
    document.getElementById("date").value !== '') {
    const number = document.getElementById("number").value;
    const date = document.getElementById("date").value;
    updateFlightList(number, date);
  } else {
    alert("Error in pointing information!")
  }
};

const PageSearch = ({airports, fetchAirports, fetchTable, fetchFlights, flights, loading, restoreFlights}) => {
  let table = "";
  if (flights.length !== 0 && flights[0].arrivals===undefined) {
    const items = (arr) => {
      return arr.map((item, id) => {
        return <FlightItemSmall flights={item} key={id}/>
      })
    };
    table = (<div>
      <p>This is your information:</p>
      <table className="table">
        <thead>
        <tr>
          <th>Flight</th>
          <th>Departure</th>
          <th>Arrival</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        {items(flights)}
        </tbody>
      </table>
    </div>);
  } else if (flights.length !== 0) {
    table = (
      <div>
        <FlightList flights={flights} dest={dest}/>
      </div>
    )
  } else {
    table = <h3>Please fill the form to see timetable</h3>;
  }

  if (loading) {
    table = <Spinner/>;
  }

  return (
    <div className="py-6">
      <div className="container jumbotron">
        <h1 className="h1">Check flights to your place</h1>
        <p>Here you can check all the flights to exact airport from any place in the world.</p>
        <div className="row">
          <div className="col-sm-3">
            <form className="form">
              <div className="form-group row">
                <div className="col-12">
                  <label htmlFor="timetable" className="col-form-label">Timetable airport</label>
                  <input id="departure" className="form-control mr-sm-2 dropdown-toggle" aria-haspopup="true"
                         data-toggle="dropdown" type="text" placeholder="Name of airport" aria-label="Departure" required
                         onChange={(e) => updateSuggests(fetchAirports, e.target.value)}/>
                  <div className="dropdown-menu w-auto">
                    {autoComplete(airports, "departure")}
                  </div>
                </div>
              </div>
              <button className="btn btn-outline-success my-2 my-sm-1" type="submit"
                      onClick={(e) => OnSubmit(e, fetchTable, restoreFlights)}>Search</button>
            </form>
            <form className="form">
              <div className="form-group row">
                <div className="col-12">
                  <label htmlFor="number" className="col-form-label">Search flight</label>
                  <input id="number" className="form-control mr-sm-2"
                         type="text" placeholder="Flight number" aria-label="number" required/>
                </div>
                <div className="col-12">
                  <label htmlFor="date" className="col-form-label">Date</label>
                  <input id="date" className="form-control mr-sm-2"
                         type="date" aria-label="date" required/>
                </div>
              </div>
              <button className="btn btn-outline-success my-2 my-sm-1" type="submit"
                      onClick={(e) => searchFlight(e, fetchFlights, restoreFlights)}>Search</button>
            </form>
          </div>
          <div className="col-sm-9" id="timetable">
            <ErrorBoundry>
              {table}
            </ErrorBoundry>
          </div>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = ({airportsList: {airports}, flightList: {flights, loading} }) => {
  return {
    airports: airports,
    flights: flights,
    loading: loading
  }
};

const mapDispatchToProps = (dispatch, { airportService, flightService }) => {
  return {
    fetchAirports: fetchAirports(airportService, dispatch),
    fetchTable: fetchTable(flightService, dispatch),
    fetchFlights: fetchFlights(flightService, dispatch),
    restoreFlights: restoreFlights(dispatch)
  }
};

export default compose(
  withAirportService(), withFlightService(),
  connect(mapStateToProps, mapDispatchToProps)
)(PageSearch);