import React from "react";

const {
  Provider: AutocompleteServiceProvider,
  Consumer: AutocompleteServiceConsumer
} = React.createContext();

export {
  AutocompleteServiceConsumer,
  AutocompleteServiceProvider
}