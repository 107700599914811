import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import { Provider } from 'react-redux';
import ErrorBoundry from "./components/error/error-boundry";
import store from "./store";

const Index = () => {
  return (
    <Provider store={store}>
      <ErrorBoundry>
        <App/>
      </ErrorBoundry>
    </Provider>
  )
};

ReactDOM.render(<Index/>, document.getElementById('root'));