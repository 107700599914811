import React from "react";

const {
  Provider: FlightServiceProvider,
  Consumer: FlightServiceConsumer
} = React.createContext();

export {
  FlightServiceConsumer,
  FlightServiceProvider
}